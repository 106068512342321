<template>
    <div class="box">
        <nl-table
            default-sort="keyword"
            default-sort-direction="asc"
            :columns.sync="columns"
            :force-update="forceUpdate"
            :get-data="getAlerts"
            :left-buttons="[
                {
                    label: $t('alertes.add'),
                    icon: 'plus-circle',
                    type: 'is-warning',
                    action: openAlertModal
                }
            ]"
            :right-buttons="[
                {
                    label: $t('global.instructions'),
                    icon: 'info-circle',
                    type: 'is-warning',
                    action: openInstructions
                }
            ]"
        >
            <template
                #keyword="{
                    props: {
                        row: { keyword }
                    }
                }"
            >
                <a role="button" @click="goToSearch(keyword)">
                    {{ keyword }}
                </a>
            </template>
            <template #lang="{ props: { row } }">
                <flag :iso="row.lang === 'com_en' ? 'gb' : 'fr'" />
            </template>
            <template
                #langSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small" expanded>
                    <option :value="null">-</option>
                    <option value="fr_fr">FR</option>
                    <option value="com_en">EN</option>
                </b-select>
            </template>
            <template #actions="{ props: { row } }">
                <div class="d-flex" style="gap: 0.5rem">
                    <b-button type="is-light" icon-pack="fas" icon-left="cog" @click="edit(row)" />
                    <b-button type="is-danger" icon-pack="fas" icon-left="trash" @click="deleteItem(row)" />
                </div>
            </template>
        </nl-table>
        <add-alert v-model="modalData" :active.sync="showAlertModal" :call-back="addOrEditAlert" />
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import AddAlert from '@/components/Alerte/AddAlert.vue'
    import { Flag } from 'vue-flag-icon/components'

    export default {
        components: { AddAlert, NlTable, Flag },
        title: 'alertes.title',
        data() {
            return {
                loading: false,
                editable: null,
                forceUpdate: false,
                columns: [
                    {
                        custom: true,
                        centered: false,
                        field: 'keyword',
                        label: this.$t('global.keyword')
                    },
                    {
                        centered: false,
                        field: 'destinataire',
                        label: this.$t('global.recipient')
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'lang',
                        label: this.$t('language.title')
                    },
                    {
                        field: 'volume',
                        label: this.$t('alertes.searchquantity'),
                        placeholder: 'Min.',
                        searchField: 'min_volume'
                    },
                    {
                        field: 'top10',
                        label: 'Top 10',
                        placeholder: 'Min.',
                        searchField: 'min_top10'
                    },
                    {
                        field: 'top100',
                        label: 'Top 100',
                        placeholder: 'Min.',
                        searchField: 'min_top100'
                    },
                    {
                        field: 'pricing',
                        label: this.$t('global.maxprice'),
                        placeholder: 'Min.',
                        searchField: 'min_pricing'
                    },
                    {
                        custom: true,
                        field: 'actions',
                        label: 'Actions',
                        required: true,
                        searchable: false
                    }
                ],
                modalData: {},
                showAlertModal: false
            }
        },
        computed: {
            ...mapGetters('alert', ['list', 'total']),
            ...mapGetters('auth', ['user'])
        },
        methods: {
            ...mapActions('alert', {
                getAlerts: 'list',
                createAlert: 'create',
                updateAlert: 'update',
                deleteAlert: 'delete'
            }),
            ...mapMutations('search', {
                setFilters: 'setFilters'
            }),

            edit(alert) {
                this.modalData = {
                    id: alert.id,
                    keyword: alert.keyword,
                    lang: alert.lang,
                    recipient: alert.destinataire,
                    volume: alert.volume,
                    top10: alert.top10,
                    top100: alert.top100,
                    price: alert.pricing
                }
                this.showAlertModal = true
            },

            deleteItem(alert) {
                confirm('Voulez-vous effacer cette alerte ?') &&
                    this.deleteAlert(alert.id)
                        .then(() => {
                            this.forceUpdate = !this.forceUpdate
                        })
                        .catch(response => {
                            if (response) {
                                alert(response.data.message)
                            }
                        })
                        .finally()
            },

            openAlertModal() {
                this.modalData = {
                    recipient: this.user.email,
                    lang: this.user.locale === 'fr' ? 'fr_fr' : 'com_en',
                    volume: 0,
                    price: 0,
                    top10: 0,
                    top100: 0
                }
                this.showAlertModal = true
            },

            openInstructions() {
                window.open(
                    'https://www.nextlevel.link/fonctionnalite-alertes-articles-disponibles-backlink.html',
                    '_blank'
                )
            },

            addOrEditAlert() {
                if (
                    this.modalData.keyword != null &&
                    this.modalData.lang != null &&
                    this.modalData.recipient != null &&
                    this.modalData.volume != null &&
                    this.modalData.top10 != null &&
                    this.modalData.top100 != null &&
                    this.modalData.price != null
                ) {
                    if (this.modalData.id) {
                        this.editAlert()
                    } else {
                        this.addAlert()
                    }
                }
            },

            addAlert() {
                this.createAlert({
                    ...this.modalData,
                    destinataire: this.modalData.recipient,
                    pricing: this.modalData.price
                })
                    .then(() => {
                        this.showAlertModal = false
                        this.modalData = {}
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(response => {
                        if (response.status === 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            },

            editAlert() {
                this.updateAlert({
                    ...this.modalData,
                    destinataire: this.modalData.recipient,
                    pricing: this.modalData.price
                })
                    .then(() => {
                        this.showAlertModal = false
                        this.modalData = {}
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(response => {
                        if (response.status === 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            },
            goToSearch(keyword) {
                const route = this.$router.resolve({
                    name: 'SearchByKeywords',
                    query: { keyword }
                })
                window.open(route.href, '_blank')
            }
        }
    }
</script>
