var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('nl-table',{attrs:{"default-sort":"keyword","default-sort-direction":"asc","columns":_vm.columns,"force-update":_vm.forceUpdate,"get-data":_vm.getAlerts,"left-buttons":[
            {
                label: _vm.$t('alertes.add'),
                icon: 'plus-circle',
                type: 'is-warning',
                action: _vm.openAlertModal
            }
        ],"right-buttons":[
            {
                label: _vm.$t('global.instructions'),
                icon: 'info-circle',
                type: 'is-warning',
                action: _vm.openInstructions
            }
        ]},on:{"update:columns":function($event){_vm.columns=$event}},scopedSlots:_vm._u([{key:"keyword",fn:function({
                props: {
                    row: { keyword }
                }
            }){return [_c('a',{attrs:{"role":"button"},on:{"click":function($event){return _vm.goToSearch(keyword)}}},[_vm._v(" "+_vm._s(keyword)+" ")])]}},{key:"lang",fn:function({ props: { row } }){return [_c('flag',{attrs:{"iso":row.lang === 'com_en' ? 'gb' : 'fr'}})]}},{key:"langSearch",fn:function({
                props: {
                    filters,
                    column: { field }
                }
            }){return [_c('b-select',{attrs:{"size":"is-small","expanded":""},model:{value:(filters[field]),callback:function ($$v) {_vm.$set(filters, field, $$v)},expression:"filters[field]"}},[_c('option',{domProps:{"value":null}},[_vm._v("-")]),_c('option',{attrs:{"value":"fr_fr"}},[_vm._v("FR")]),_c('option',{attrs:{"value":"com_en"}},[_vm._v("EN")])])]}},{key:"actions",fn:function({ props: { row } }){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[_c('b-button',{attrs:{"type":"is-light","icon-pack":"fas","icon-left":"cog"},on:{"click":function($event){return _vm.edit(row)}}}),_c('b-button',{attrs:{"type":"is-danger","icon-pack":"fas","icon-left":"trash"},on:{"click":function($event){return _vm.deleteItem(row)}}})],1)]}}])}),_c('add-alert',{attrs:{"active":_vm.showAlertModal,"call-back":_vm.addOrEditAlert},on:{"update:active":function($event){_vm.showAlertModal=$event}},model:{value:(_vm.modalData),callback:function ($$v) {_vm.modalData=$$v},expression:"modalData"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }